import React from 'react';
import StomachSimulation from './simulations/StomachSimulation';
import { useRef } from 'react';
import Tutorial from './/simulations/Tutorial';

export default function App() {
  const canvasRef = useRef(null);
  const desiredWidth = 1300;
  const desiredHeight = 700;

  return (
    <div className='app-container'>
      <h1>Enzymatic Digestion Simulation</h1>
      <Tutorial />
      <StomachSimulation canvasRef={canvasRef} desiredWidth={desiredWidth} desiredHeight={desiredHeight} />
      <p className='version-number'>V1.0.1</p>
      <div className='icon-credits'>
        Icons created by{' '}
        <a href='https://www.flaticon.com/de/autoren/freepik' title='Freepik'>
          {' '}
          Freepik{' '}
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/de/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
    </div>
  );
}
