import React from 'react';
import { useState } from 'react';

// Slider is arrow function which takes one object with properties
const Slider = ({ label, min, max, value, onChange, type }) => {
  const handleChange = (e) => {
    const newValue = parseFloat(e.target.value);
    onChange(newValue);
  };

  const [showParagraph, setShowParagraph] = useState(false);
  const [delayTimeout, setDelayTimeout] = useState(null);

  const handleTempMouseEnter = () => {
    clearTimeout(delayTimeout);
    const timeoutId = setTimeout(() => {
      setShowParagraph(true);
    }, 200);
    setDelayTimeout(timeoutId);
  };

  const handleWaterMouseLeave = () => {
    clearTimeout(delayTimeout);
    setShowParagraph(false);
  };

  return (
    <div className='slider'>
      <div className='row' onMouseEnter={handleTempMouseEnter} onMouseLeave={handleWaterMouseLeave}>
        <label className='temp-pH-label'>{label}: </label>
        <span>{value} </span>
      </div>
      <input type='range' min={min} max={max} step={1} value={value} onChange={handleChange} />
      {showParagraph && (
        <div className='pop-up-control' id='temp-pop-up'>
          <p>
            {type === 'temp'
              ? 'Temperature is the measure of particle motion in a substance. In the body, it is around 37°C. Brownian motion, driven by temperature, is the random movement of particles in fluids, vital for processes like nutrient diffusion.'
              : 'The pH is a measure of the concentration of hydrogen ions (protons) of a solution. It measures acidity or alkalinity on a scale from 0 to 14, crucial for biological and chemical processes. In the human body, maintaining the right pH is essential for various functions, with 7 being neutral, below 7 acidic, and above 7 alkaline.'}
          </p>
        </div>
      )}
    </div>
  );
};

export default Slider;
