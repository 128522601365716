import React from 'react';
import { useState } from 'react';

const Legend = ({ showCarbohydrates, showProteins, showLipids }) => {
  const [showWaterParagraph, setShowWaterParagraph] = useState(false);
  const [showProtonParagraph, setShowProtonParagraph] = useState(false);
  const [showCarbsParagraph, setShowCarbsParagraph] = useState(false);
  const [showProteinsParagraph, setShowProteinsParagraph] = useState(false);
  const [showLipidsParagraph, setShowLipidsParagraph] = useState(false);
  const [delayTimeout, setDelayTimeout] = useState(null);

  const handleWaterMouseEnter = () => {
    clearTimeout(delayTimeout);
    const timeoutId = setTimeout(() => {
      setShowWaterParagraph(true);
    }, 200);
    setDelayTimeout(timeoutId);
  };

  const handleWaterMouseLeave = () => {
    clearTimeout(delayTimeout);
    setShowWaterParagraph(false);
  };

  const handleProtonMouseEnter = () => {
    clearTimeout(delayTimeout);
    const timeoutId = setTimeout(() => {
      setShowProtonParagraph(true);
    }, 200);
    setDelayTimeout(timeoutId);
  };

  const handleProtonMouseLeave = () => {
    clearTimeout(delayTimeout);
    setShowProtonParagraph(false);
  };

  const handleCarbsMouseEnter = () => {
    clearTimeout(delayTimeout);
    const timeoutId = setTimeout(() => {
      setShowCarbsParagraph(true);
    }, 200);
    setDelayTimeout(timeoutId);
  };

  const handleCarbsMouseLeave = () => {
    clearTimeout(delayTimeout);
    setShowCarbsParagraph(false);
  };

  const handleProteinsMouseEnter = () => {
    clearTimeout(delayTimeout);
    const timeoutId = setTimeout(() => {
      setShowProteinsParagraph(true);
    }, 200);
    setDelayTimeout(timeoutId);
  };

  const handleProteinsMouseLeave = () => {
    clearTimeout(delayTimeout);
    setShowProteinsParagraph(false);
  };

  const handleLipidsMouseEnter = () => {
    clearTimeout(delayTimeout);
    const timeoutId = setTimeout(() => {
      setShowLipidsParagraph(true);
    }, 200);
    setDelayTimeout(timeoutId);
  };

  const handleLipidsMouseLeave = () => {
    clearTimeout(delayTimeout);
    setShowLipidsParagraph(false);
  };

  // Calculate vertices
  const vertices = [];
  const x = 50; // Example x coordinate
  const y = 50; // Example y coordinate
  const sideLength = 15; // Example side length
  for (let i = 0; i < 6; i++) {
    const angle = (Math.PI / 3) * i;
    const vertexX = x + sideLength * Math.cos(angle);
    const vertexY = y + sideLength * Math.sin(angle);
    vertices.push({ x: vertexX, y: vertexY });
  }

  // CSS styles for the hexagon
  const hexagonStyle = {
    position: 'relative',
    width: `${sideLength * 2}px`, // Twice the side length for the width
    height: `${sideLength * Math.sqrt(3)}px`, // Height of an equilateral triangle (sqrt(3) * side length)
    backgroundColor: '#BCE6D1',
    clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
    margin: '0 0 0 0.5em',
  };

  return (
    <div className='legend-container'>
      <h2>Molecules & Nurients</h2>
      <div className='legend-div'>
        <div
          className='legend-water-particle'
          onMouseEnter={handleWaterMouseEnter}
          onMouseLeave={handleWaterMouseLeave}
        ></div>
        {showWaterParagraph && (
          <div className='pop-up' id='water'>
            <p className='legend-description'>
              Water molecules are vital for dissolving substances and participating in key chemical reactions, crucial
              for life and ecosystems.
            </p>
          </div>
        )}
      </div>
      <div className='legend-div'>
        <div className='clickbox' onMouseEnter={handleProtonMouseEnter} onMouseLeave={handleProtonMouseLeave}>
          <div className='legend-proton-particle'></div>
        </div>
        {showProtonParagraph && (
          <div className='pop-up' id='proton'>
            <p className='legend-description'>
              Protons dictate a solution's pH, with higher concentrations leading to lower pH values, signifying
              increased acidity. All enzymes have an optimum pH range.
            </p>
          </div>
        )}
      </div>
      {showCarbohydrates && (
        <div className='legend-div'>
          <div
            className='legend-carb-particle'
            onMouseEnter={handleCarbsMouseEnter}
            onMouseLeave={handleCarbsMouseLeave}
            style={hexagonStyle}
          ></div>
          {showCarbsParagraph && (
            <div className='pop-up' id='carbs'>
              <p className='legend-description'>
                Carbohydrates, depicted as hexagons due to their 6 carbon atoms, are vital energy sources composed of
                carbon, hydrogen, and oxygen. They fuel brain and muscle function and are found in grains, fruits, and
                vegetables.
              </p>
            </div>
          )}
        </div>
      )}
      {showProteins && (
        <div className='legend-div'>
          <div
            className='legend-protein-particle'
            onMouseEnter={handleProteinsMouseEnter}
            onMouseLeave={handleProteinsMouseLeave}
          ></div>
          {showProteinsParagraph && (
            <div className='pop-up' id='proteins'>
              <p className='legend-description'>
                Proteins are essential macronutrients crucial for the structure, function, and regulation of the body's
                tissues and organs. Composed of amino acids, proteins play diverse roles in the body, including enzyme
                catalysis, immune response, and muscle contraction.
              </p>
            </div>
          )}
        </div>
      )}
      {showLipids && (
        <div className='legend-div'>
          <div
            className='legend-lipid-particle'
            onMouseEnter={handleLipidsMouseEnter}
            onMouseLeave={handleLipidsMouseLeave}
          ></div>
          {showLipidsParagraph && (
            <div className='pop-up' id='lipids'>
              <p className='legend-description'>
                Lipids, often referred to as fats, are macronutrients vital for energy storage, insulation, and cellular
                structure. Consisting of fatty acids and glycerol, lipids serve as building blocks for cell membranes
                and play key roles in hormone synthesis and nutrient absorption.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Legend;
