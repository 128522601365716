import React from 'react';

// Slider is arrow function which takes one object with properties
const Buttons = ({ onNutrientAdd, onEnzymeAdd }) => {
  return (
    <div className='button-controls'>
      <h2>Nurients</h2>
      <h2>Enzymes</h2>
      <button className='buttons' onClick={() => onNutrientAdd('carbohydrates')}>
        Add Carbohydrates
      </button>
      <button id='amylase' className='buttons' onClick={() => onEnzymeAdd('amylase', 'carbohydrates')}>
        Add Amylase
      </button>
      <button className='buttons' onClick={() => onNutrientAdd('proteins')}>
        Add Proteins
      </button>
      <button id='pepsin' className='buttons' onClick={() => onEnzymeAdd('pepsin', 'proteins')}>
        Add Pepsin
      </button>
      <button className='buttons' onClick={() => onNutrientAdd('lipids')}>
        Add Lipids
      </button>
      <button id='lipase' className='buttons' onClick={() => onEnzymeAdd('lipase', 'lipids')}>
        Add Lipase
      </button>
    </div>
  );
};

export default Buttons;
