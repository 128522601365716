import React, { useState } from 'react';

function Tutorial({ onClose }) {
  const [showOverlay, setShowOverlay] = useState(false);

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  return (
    <div className='tutorial-div'>
      <h4>How To Use</h4>
      <div className='question-mark' onClick={toggleOverlay}></div>
      {showOverlay && (
        <div className='overlay'>
          <div className='overlay-content'>
            <h2>Explore Optimal Conditions for Enzymes</h2>
            <p>
              Welcome to the Enzymatic Digestion Simulation! Here, you can dynamically adjust pH and temperature
              settings to understand how they affect enzyme activity. Your goal is to discover the optimal conditions
              for each of the three enzymes involved in macronutrient digestion.
            </p>
            <p>
              Hover over the controls on the top and the molecule symbols on the right to learn more about what you see.
            </p>
            <ol className='step-list'>
              <li className='step'>
                <span className='step-number'>1.</span>
                <div className='step-description'>
                  <h3>Step 1: Add Nutrients</h3>
                  <p>Click on the buttons to introduce different macronutrients into the simulation.</p>
                </div>
              </li>
              <li className='step'>
                <span className='step-number'>2.</span>
                <div className='step-description'>
                  <h3>Step 2: Select Enzymes</h3>
                  <p>
                    After adding nutrients, choose and add specific enzymes tailored to break down the selected
                    macronutrients.
                  </p>
                </div>
              </li>
              <li className='step'>
                <span className='step-number'>3.</span>
                <div className='step-description'>
                  <h3>Step 3: Adjust pH and Temperature</h3>
                  <p>Utilize the sliders to modify pH and temperature values within the simulation environment.</p>
                </div>
              </li>
              <li className='step'>
                <span className='step-number'>4.</span>
                <div className='step-description'>
                  <h3>Step 5: Observe Enzyme Activity</h3>
                  <p>
                    Monitor the enzyme activity level indicator to assess how changes in pH and temperature impact
                    enzymatic function.
                  </p>
                </div>
              </li>
              <li className='step'>
                <span className='step-number'>5.</span>
                <div className='step-description'>
                  <h3>Step 5: Find Optimal Conditions:</h3>
                  <p>
                    Experiment with different pH and temperature combinations to identify the conditions where each
                    enzyme works most effectively.
                  </p>
                </div>
              </li>
            </ol>
          </div>
          <div className='close-button' onClick={toggleOverlay}></div>
        </div>
      )}
    </div>
  );
}

export default Tutorial;
